module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"wordpress":[{"protocol":"https","domain":"nodustinfra.lignostar.com","language":"nl","normalizers":[{"name":"acf"}],"includedRoutes":["**/projects"]},{"protocol":"https","domain":"nodustinfra.lignostar.com","language":"en","normalizers":[{"name":"acf"}],"includedRoutes":["**/projects"]},{"protocol":"https","domain":"nodustinfra.lignostar.com","language":"de","normalizers":[{"name":"acf"}],"includedRoutes":["**/projects"]}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NoDustInfra.com","short_name":"NoDustInfra","start_url":"/?pwa=true","background_color":"black","theme_color":"#ffcf00","display":"minimal-ui","icon":"src/images/favicon.png","lang":"nl","localize":[{"start_url":"/en/","lang":"en"},{"start_url":"/de/","lang":"de"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b14c31f5b8636e7bfc6dfdc0f6400f8c"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-FBGNS2F04L","UA-36988470-2"],"pluginConfig":{"head":false,"respectDNT":true,"anonymize_ip":true,"exclude":[]}},
    }]
